import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { ADD_NEW_COUPAN, DELETE_COUPAN, GET_COUPANS, UPDATE_COUPAN,  } from "./actionTypes"

import {
  getCoupansSuccess,
  getCoupansFail,
  addCoupanFail,
  addCoupanSuccess,
  updateCoupanSuccess,
  updateCoupanFail,
  deleteCoupanSuccess,
  deleteCoupanFail,
} from "./actions"

import { getCoupan, addCoupan, updateCoupan, deleteCoupan } from "../../helpers/coupan_helper"

import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';



function* fetchCoupan() {
  try {
    const response = yield call(getCoupan)
    yield put(getCoupansSuccess(response.coupans))
  } catch (error) {
    yield put(getCoupansFail(error))
  }
}

function* onUpdateCoupan({ payload: coupan }) {
  try {
    const response = yield call(updateCoupan, coupan)
    response.coupan.id = response.coupan._id;
    yield put(updateCoupanSuccess(response.coupan))
    toast.success("Updated Successfully", { autoClose: 2000 });
  } catch (error) {
    console.log(error)
    yield put(updateCoupanFail(error))
    toast.error("Updated Failed", { autoClose: 2000 });
  }
}

function* onDeleteCoupan({ payload: coupan }) {
  try {
    const response = yield call(deleteCoupan, coupan)
    yield put(deleteCoupanSuccess(response.coupan._id))
    toast.success("Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteCoupanFail(error))
    toast.error("Deleted Failed", { autoClose: 2000 });
  }
}

function* onAddCoupan({ payload: user }) {
  try {
    const response = yield call(addCoupan, user)
    response.coupan.id = response.coupan._id;
    yield put(addCoupanSuccess(response.coupan))
    toast.success("Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addCoupanFail(error))
    toast.error("Added Failed", { autoClose: 2000 });
  }
}

function* coupanSaga() {
  yield takeEvery(GET_COUPANS, fetchCoupan)
  yield takeEvery(ADD_NEW_COUPAN, onAddCoupan)
  yield takeEvery(UPDATE_COUPAN, onUpdateCoupan)
  yield takeEvery(DELETE_COUPAN, onDeleteCoupan)
}

export default coupanSaga;
