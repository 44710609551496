import {
  GET_COUPANS_SUCCESS,
  GET_COUPANS_FAIL,
  ADD_COUPAN_SUCCESS,
  ADD_COUPAN_FAIL,
  UPDATE_COUPAN_SUCCESS,
  UPDATE_COUPAN_FAIL,
  DELETE_COUPAN_SUCCESS,
  DELETE_COUPAN_FAIL,
  GET_COUPAN_SUCCESS,
  GET_COUPAN_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  coupan: [],
  error: {},
  loading: true
}

const coupans = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_COUPANS_SUCCESS:
      return {
        ...state,
        coupan: action.payload,
        loading: true
      }

    case GET_COUPANS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_COUPAN_SUCCESS:

      return {
        ...state,
        coupan: [...state.coupan, action.payload],
      }

    case ADD_COUPAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_COUPAN_SUCCESS:
      return {
        ...state,
        coupan: action.payload,
      }

    case UPDATE_COUPAN_SUCCESS:
      return {
        ...state,
        coupan: state.coupan.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
      }

    case UPDATE_COUPAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_COUPAN_SUCCESS:
      return {
        ...state,
        coupan: state.coupan.filter(
          user => user.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_COUPAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_COUPAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default coupans
