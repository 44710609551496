import {
  GET_COUPANS,
  GET_COUPANS_FAIL,
  GET_COUPANS_SUCCESS,
  ADD_NEW_COUPAN,
  ADD_COUPAN_SUCCESS,
  ADD_COUPAN_FAIL,
  UPDATE_COUPAN,
  UPDATE_COUPAN_SUCCESS,
  UPDATE_COUPAN_FAIL,
  DELETE_COUPAN,
  DELETE_COUPAN_SUCCESS,
  DELETE_COUPAN_FAIL,
} from "./actionTypes"

export const getCoupans = () => ({
  type: GET_COUPANS,
})

export const getCoupansSuccess = coupan => ({
  type: GET_COUPANS_SUCCESS,
  payload: coupan,
})

export const addNewCoupan = coupan => ({
  type: ADD_NEW_COUPAN,
  payload: coupan,
})

export const addCoupanSuccess = coupan => ({
  type: ADD_COUPAN_SUCCESS,
  payload: coupan,
})

export const addCoupanFail = error => ({
  type: ADD_COUPAN_FAIL,
  payload: error,
})

export const getCoupansFail = error => ({
  type: GET_COUPANS_FAIL,
  payload: error,
})

export const updateCoupan = coupan => ({
  type: UPDATE_COUPAN,
  payload: coupan,
})

export const updateCoupanSuccess = coupan => ({
  type: UPDATE_COUPAN_SUCCESS,
  payload: coupan,
})

export const updateCoupanFail = error => ({
  type: UPDATE_COUPAN_FAIL,
  payload: error,
})

export const deleteCoupan = coupan => ({
  type: DELETE_COUPAN,
  payload: coupan,
})

export const deleteCoupanSuccess = coupan => ({
  type: DELETE_COUPAN_SUCCESS,
  payload: coupan,
})

export const deleteCoupanFail = error => ({
  type: DELETE_COUPAN_FAIL,
  payload: error,
})
